import React, { useState, useEffect } from 'react';

import { useForm } from 'react-hook-form';

import styles from './ModalForm.module.scss';
import ModalPopUp from './ModalPopUp';


const ModalForm = ({
  isOpen,
  title,
  description,
  leftButtonText='',
  leftButtonFunction,
  closeButtonText='CANCEL',
  onClose,
  submitButtonText='CONFIRM',
  onSubmit,
  config,
  children,
}) => {
  /**
   * Creates a modal form.
   * @param {boolean} isOpen          Pass the open state of the window
   * @param {string} title            Define window title text
   * @param {string} description      Define the description
   * @param {string} leftButtonText   Text of the button at the left hand side of the form
   * @param {function} leftButtonFunction   Defines what to do when the left button is clicked
   * @param {string} closeButtonText  Text of the button to close the form
   * @param {function} onClose        Defines how to close the window
   * @param {string} submitButtonText Text of the button to aprove the form
   * @param {function} onSubmit       Form handler on submit
   * @param {Array[Object]} config    Array of configurations for each of the fields
   * with the following structure:
   * {
   *  name: Unique component name
   *  label: Label text above the field
   *  type: Input type
   *  placeholder: placeholder string for text forms
   *  restricted: A placeholder string for text forms which are unchangeable
   *  validation: Validation function according to library react-hook-forms
   *              Please refer to the documentation here:
   *              https://react-hook-form.com/docs/useform/register
   * dropdownValues: A list of options for a dropdown box
   * selected: which of those dropdownValues are selected
   * }
   * @param {React.JSX} children    Components to be displayed
   * above the form but below the description
   * @returns {React.JSX}           Modal form component
   */
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    getValues,
    watch,
  } = useForm();

  useEffect(() => {
    config?.forEach((field) => {
      if (field.restricted || field.defaultValue) {
        setValue(field.name, field.restricted ?? field.defaultValue);
      } else if (field.type == 'text') {
        setValue(field.name, '');
        clearErrors(field.name);
      } else if (field.type == 'radio') {
        if (field.values && field.values.length > 0) {
          setValue(field.name, field.values[0].value);
        }
      }
    });
  }, [config, setValue]);

  const inputElement = (field) => {
    const [enabled, setEnabled] = useState(field.toggled ? field.toggled.default : false);
    let inputType;

    const handleDecimalInput = (e) => {
      let value = e.target.value;
      if (value) {
        value = parseFloat(value).toFixed(2); // Format to 2 decimal places
      }
      setValue(field.name, value);
    };

    switch (field.type) {
      case 'text':
      case 'password':
        inputType = <input
          id={field.name}
          name={field.name}
          type={field.type}
          {...register(field.name, field.validation)}
          placeholder={field.placeholder}
          className={errors[field.name] ? styles.error : ''}
          disabled={field.restricted}
          hidden={field.hidden}
          defaultValue={field.defaultValue}
          style={{ cursor: field.restricted ? 'not-allowed' : 'auto',
            color: field.restricted ? 'black': 'inherit',
          }}
          spellCheck={false}
        />;
        break;
      case 'number':
      case 'decimal':
        const validation = { ...field.validation };
        if (enabled) validation.required = 'Empty';
        else validation.required = false;
        inputType = <div>
          {(field.unit === '£') && <span className={`${styles.inputNumberUnit} ${styles.leftUnit}`}>
            {field.unit}</span>}
          <input
            id={field.name}
            type='number'
            defaultValue={field.value}
            onChange={(e) => setValue(field.name, e.target.value)}
            min={field.min}
            max={field.max}
            {...register(field.name, validation)}
            className={errors[field.name] ? styles.error : ''}
            step={field.type === 'decimal' ? '0.01' : undefined}
            onBlur={field.type === 'decimal' ? handleDecimalInput : undefined}
          />
          {(field.unit !== '£') && <span className={styles.inputNumberUnit}>{field.unit}</span>}
        </div>;
        break;
      case 'date':
        inputType = <input
          id={field.name}
          type={field.type}
          defaultValue={field.value.toISOString().split('T')[0]}
          readOnly={field.readonly}
          onChange={(e) => setValue(field.name, e.target.value)}
          {...register(field.name, field.validation)}
          className={errors[field.name] ? styles.error : ''}
        />;
        break;
      case 'radio':
        inputType = <fieldset
          id={field.name}
          name={field.name}
          className={errors[field.name] ? styles.error : ''}
        >
          {field.values.map((radio) => <label
            key={radio.value}
            htmlFor={`${field.name}-${radio.value}`}
            className={styles.radioLabel}
          >
            <input
              id={`${field.name}-${radio.value}`}
              name={field.name}
              type={field.type}
              value={radio.value}
              disabled={radio.disabled}
              {...register(field.name, field.validation)}
            />
            {radio.label}
          </label>)}
        </fieldset>;
        break;
      case 'dropdown':
        inputType = (
          <div className={styles.customSelect}>
            <select
              id={field.name}
              name={field.name}
              {...register(field.name, field.validation)}
              defaultValue={field.selected}
              onChange={(e) => setValue(field.name, e.target.value)}
              disabled={field.disabled}
            >
              {field.dropdownValues.map((dropdownValue, index) => (
                <option key={index} value={dropdownValue.value}>{dropdownValue.label}</option>
              ))}
            </select>
          </div>
        );
        break;
      case 'custom':
        inputType = React.cloneElement(
            field.component,
            {
              ...field.component.props, // Spread existing props
              'register': register,
              'watch': watch,
              'setValue': setValue,
              'errors': errors,
            },
        );
        break;
    }

    if (field.toggled) {
      inputType = <div className={styles.togglableGroup}>
        <input
          type='checkbox'
          className={styles.checkbox}
          checked={enabled}
          {...register(`${field.name}${field.type ? '-enabled' : ''}`)}
          onChange={(e) => setEnabled(e.target.checked)}
        />
        <span className={styles.togglable}>{inputType}</span>
      </div>;
    }

    return inputType;
  };

  const parseData = (data) => {
    // Remove data field from form if its checkbox is disabled
    Object.keys(data).forEach((key) => {
      if (key.includes('-enabled')) {
        const baseKey = key.replace('-enabled', '');

        if (data[key] === 'false' || data[key] === false) {
          delete data[baseKey];
        }
        delete data[key];
      }
    });

    return data;
  };

  return (
    <ModalPopUp
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      description={description}
    >
      {children}
      <form onSubmit={handleSubmit((data) => onSubmit(parseData(data)))} className={styles.form}>
        {config?.map((field, index) => (
          <div className={styles.formGroup} key={index}>
            {field.label && <label className={`${styles['form-label']}`}
              style={{ color: errors[field.name] ? 'red' : '' }}
              htmlFor={field.name}>
              {field.label}
            </label>}
            {inputElement(field)}
            {errors[field.name] && <span className={styles.errorMessage}>
              {errors[field.name].message}
            </span>}
          </div>
        ))}
        <div className={styles.buttonContainer}>
          {
            leftButtonText && <button
              className={styles.formButton}
              type='button'
              onClick={(data) => leftButtonFunction(getValues())}
            >{leftButtonText}
            </button>
          }
          <div className={styles.buttonRightContainer}>
            <button
              className={styles.formButton}
              type='button'
              onClick={onClose}
            >
              {closeButtonText}
            </button>
            <button className={styles.formButton} type='submit'>{submitButtonText}</button>
          </div>
        </div>
      </form>
    </ModalPopUp>
  );
};

export default ModalForm;
